import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { AktsucheService } from "../../api/services/aktsuche.service";
import { Router } from "@angular/router";
import { BackService } from "../../services/back.service";
import { IonModal } from "@ionic/angular";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AuthService } from "../../services/auth.service";
import { UserRole } from "../enums/global-enums";

@Component({
  selector: "app-aktsuche-suchbereich",
  templateUrl: "./aktsuche-suchbereich.component.html",
  styleUrls: ["./aktsuche-suchbereich.component.scss"],
  standalone: false,
})
export class AktsucheSuchbereichComponent implements OnInit, AfterViewInit {
  @Input() emailId?: string;
  @Input() afterEmailZuordnen?: (zuwordnenData: any) => unknown;
  @ViewChild("emailZuordnenModal") emailZuordnenModal: IonModal;

  isSearching: boolean = false;

  dokumentenkategorieOptions = [
    { value: 7, label: "Rechnung(sprüfung)" },
    { value: 9, label: "Ablösevorschlag" },
    { value: 12, label: "KV" },
    { value: 14, label: "Rückfrage AN" },
    { value: 23, label: "UrgenzMail" },
    { value: 26, label: "Reaktion" },
    { value: 30, label: "mailManager" },
    { value: 34, label: "Termininfo" },
    { value: 35, label: "Schadeninfo" },
  ];

  userData: any;

  aktliste: any;
  selectedAkt: any;
  filter: FormGroup;
  aktZuordnung: FormGroup;

  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = [
    "Bezeichnung",
    "Gegner1Vorname",
    "Gegner1Nachname",
    "Art",
    "SchadenNummer",
    "AnlageDatum",
  ];
  displayedAdminColumns: string[] = [
    "Bezeichnung",
    "Gegner1Vorname",
    "Gegner1Nachname",
    "Klient1Name",
    "Oid",
    "Art",
    "SchadenNummer",
    "AnlageDatum",
  ];
  dataSourceMatTable: MatTableDataSource<any>;

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private aktsucheService: AktsucheService,
    private fb: FormBuilder,
    private router: Router,
    private backService: BackService,
    public authService: AuthService,
  ) {
    this.dataSourceMatTable = new MatTableDataSource([]);
  }

  ngOnInit() {
    this.oidcSecurityService.userData$.subscribe((userData) => {
      this.userData = userData;
    });
    this.filter = this.fb.group({
      vnUndAstVorname: [null],
      vnUndAstNachname: [null],
      aktnummer: [null],
      schadennummer: [null],
      sb2: [null],
      versicherung: [null],
      schadenortPlz: [null],
      schadenortOrt: [null],
      schadenortStrasse: [null],
      zeitraumAb: [this.getOneYearAgo()],
    });
    this.aktZuordnung = this.fb.group({
      dokumentenkategorie: [null, Validators.required],
    });
  }

  ngAfterViewInit() {
    this.dataSourceMatTable.sort = this.sort;
    this.dataSourceMatTable.sort.sort({
      id: "datum",
      start: "desc",
      disableClear: false,
    });
  }

  getOneYearAgo(): string {
    const today = new Date();
    today.setFullYear(today.getFullYear() - 1);
    return today.toISOString().split("T")[0];
  }

  updateAktList() {
    this.isSearching = true;
    this.aktsucheService
      .aktsucheRetrieveCreate(this.getRequestParams())
      .subscribe((result) => {
        this.aktliste = result;
        this.dataSourceMatTable.data = this.aktliste.value.map((item) => ({
          ...item,
          Art: item.Art?.Art,
        }));
        this.isSearching = false;
      });
  }

  private getRequestParams() {
    return {
      token: this.oidcSecurityService.getToken(),
      vn_und_ast_vorname: this.filter.controls.vnUndAstVorname.value,
      vn_und_ast_nachname: this.filter.controls.vnUndAstNachname.value,
      akt_nummer: this.filter.controls.aktnummer.value,
      schadennummer: this.filter.controls.schadennummer.value,
      sb2: this.filter.controls.sb2.value,
      versicherung: this.filter.controls.versicherung.value,
      schadenort_plz: this.filter.controls.schadenortPlz.value,
      schadenort_ort: this.filter.controls.schadenortOrt.value,
      schadenort_strasse: this.filter.controls.schadenortStrasse.value,
      from_date: this.filter.controls.zeitraumAb.value,
    };
  }

  onDatatableActivate(event: any, row) {
    if (this.emailId) {
      this.selectedAkt = row;
      this.emailZuordnenModal.present();
    } else {
      this.openakt(row.Oid, row.SchadenNummer);
    }
  }

  openakt(aktid, schadennummer) {
    this.backService.backPath = "/aktsuche";
    this.router.navigate(["/details"], {
      queryParams: { aktid: aktid, snr: schadennummer },
    });
  }

  cancelButtonEmailZuordnen() {
    this.emailZuordnenModal.dismiss(null, "cancel");
  }

  confirmButtonEmailZuordnen() {
    if (this.aktZuordnung.invalid) {
      this.aktZuordnung.markAllAsTouched();
      return;
    }
    this.emailZuordnenModal.dismiss(null, "confirm");
    this.afterEmailZuordnen.call(this, {
      aktId: this.selectedAkt.Oid,
      dokumentArt: this.aktZuordnung.get("dokumentenkategorie").value.value,
    });
  }

  protected readonly UserRole = UserRole;
}
