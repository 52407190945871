export enum UserRole {
  Admin = "Admin",
  BackofficeAdmin = "BOAdmin",
  Backoffice = "Backoffice",
  Regulierer = "Regulierer",
  Interessent = "InteressentInnen",
}

export enum TerminStatus {
  Vereinbart = 0,
  SpaeterVNNichtErreicht = 1,
  NichtMoeglichPostweg = 2,
  GeraetAufPostweg = 3,
  PoststueckEingelangt = 4,
  SpaeterMoeglich = 5,
}

export enum AktSparte {
  Mobil = "Mobil",
  Technik = "Technik",
}

export enum AktOrtArt {
  Schadenort = "Schadenort",
  Besichtigung = "Besichtigung",
  Sonstiger = "Sonstiger",
}

export enum TextbausteinPlatzhalter {
  Besichtigungstermin = "{Besichtigungstermin}",
  KundenprozessLink = "{KundenprozessLink}",
}

export enum MailmanagerAction {
  ASSIGN = "ASSIGN",
  PRIVATE = "PRIVATE",
  INTERN = "INTERN",
  TRASH = "TRASH",
}
