import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MailmanagerService } from "../../api/services/mailmanager.service";
import { AlertController, IonModal, ModalController } from "@ionic/angular";
import { MailmanagerAction } from "../enums/global-enums";
import { MailmanagerMailboxEmailAssignRequest } from "../../api/models/mailmanager-mailbox-email-assign-request";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { MailmanagerEmailResponse } from "../../api/models/mailmanager-email-response";
import { MailmanagerMailboxEmailContentRequest } from "../../api/models/mailmanager-mailbox-email-content-request";
import { MailmanagerMailboxEmailContentResponse } from "../../api/models/mailmanager-mailbox-email-content-response";
import { DownloadService } from "../../services/download.service";

@Component({
  selector: "app-email-content",
  templateUrl: "./email-content.component.html",
  styleUrls: ["./email-content.component.scss"],
  standalone: false,
})
export class EmailContentComponent implements OnInit {
  @ViewChild("aktsucheModal") aktsucheModal: IonModal;

  @Input({ required: true }) emailHeader: MailmanagerEmailResponse;
  @Input({ required: false })
  emailContent: MailmanagerMailboxEmailContentResponse;
  @Input({ required: true }) userSb: string;
  @Input({ required: false }) mailboxFolderId: string;
  @Input({ required: false }) mailboxEmailId: string;
  @Input({ required: false }) emlFileBlob: Blob;
  @Input({ required: false }) emlFileName: string;

  constructor(
    private mailmanagerService: MailmanagerService,
    private alertController: AlertController,
    private modalController: ModalController,
    private oidcSecurityService: OidcSecurityService,
    protected downloadService: DownloadService,
  ) {}

  ngOnInit() {
    if (this.mailboxFolderId && this.mailboxEmailId) {
      this.getMailmanagerEmailContent();
    }
  }

  openAktsucheModal() {
    this.aktsucheModal.present();
  }

  downloadEmlBlob() {
    this.downloadService.createLinkAndDownloadFile(
      this.emlFileBlob,
      this.emlFileName,
    );
  }

  cancelButtonEmail() {
    this.modalController.dismiss(null, "cancel");
  }

  private getMailboxEmailContentRequestParams(
    folderId: string,
    emailId: string,
  ): MailmanagerMailboxEmailContentRequest {
    return {
      token: this.oidcSecurityService.getToken(),
      user_id: this.userSb,
      folder_id: folderId,
      email_id: emailId,
    };
  }

  private getMailboxEmailAssignRequestParams(
    folderId: string,
    emailId: string,
    action: MailmanagerAction,
    recordId: number = null,
    documentType: number = null,
    subject: string = null,
  ): MailmanagerMailboxEmailAssignRequest {
    return {
      token: this.oidcSecurityService.getToken(),
      user_id: this.userSb,
      folder_id: folderId,
      email_id: emailId,
      action: action,
      record_id: recordId,
      document_type: documentType,
      subject: subject,
    };
  }

  async deleteEmailButton() {
    const alert = await this.alertController.create({
      header: "Diese E-Mail löschen?",
      message: "Gelöschte Emails können nicht wiederhergestellt werden!",
      buttons: [
        {
          text: "Abbrechen",
          role: "cancel",
          cssClass: "color-primary",
        },
        {
          cssClass: "color-danger",
          text: "Löschen",
          handler: () => {
            this.modalController.dismiss();
            this.mailmanagerService
              .mailmanagerMailboxEmailAssignCreate(
                this.getMailboxEmailAssignRequestParams(
                  this.mailboxFolderId,
                  this.mailboxEmailId,
                  MailmanagerAction.TRASH,
                ),
              )
              .subscribe((response) => {
                this.modalController.dismiss(null, "confirm");
              });
          },
        },
      ],
    });
    document.body.appendChild(alert);
    await alert.present();
  }

  async zuordnenOhneAktbezugButton() {
    const alert = await this.alertController.create({
      header: "Zuordnen ohne Aktbezug",
      message: "Diese E-Mail ohne Aktbezug zuordnen?",
      buttons: [
        {
          text: "Abbrechen",
          role: "cancel",
          cssClass: "color-primary",
        },
        {
          cssClass: "color-secondary",
          text: "Zuordnen",
          handler: () => {
            this.modalController.dismiss();
            this.mailmanagerService
              .mailmanagerMailboxEmailAssignCreate(
                this.getMailboxEmailAssignRequestParams(
                  this.mailboxFolderId,
                  this.mailboxEmailId,
                  MailmanagerAction.PRIVATE,
                ),
              )
              .subscribe((response) => {
                this.modalController.dismiss(null, "confirm");
              });
          },
        },
      ],
    });
    document.body.appendChild(alert);
    await alert.present();
  }

  getMailmanagerEmailContent() {
    if (this.emailContent) {
      return;
    }
    this.mailmanagerService
      .mailmanagerMailboxEmailContentRetrieveCreate(
        this.getMailboxEmailContentRequestParams(
          this.mailboxFolderId,
          this.mailboxEmailId,
        ),
      )
      .subscribe((result: MailmanagerMailboxEmailContentResponse) => {
        this.emailContent = {
          message: result.message,
          attachments: result.attachments,
        };
      });
  }

  cancelButtonAktsuche() {
    this.aktsucheModal.dismiss(null, "cancel");
  }

  afterEmailZuordnen = (zuordnenData: any) => {
    this.aktsucheModal.dismiss();
    this.mailmanagerService
      .mailmanagerMailboxEmailAssignCreate(
        this.getMailboxEmailAssignRequestParams(
          this.mailboxFolderId,
          this.mailboxEmailId,
          MailmanagerAction.ASSIGN,
          zuordnenData.aktId,
          zuordnenData.dokumentArt,
        ),
      )
      .subscribe((response) => {
        this.modalController.dismiss(null, "confirm");
      });
  };
}
