import { Component, Input, OnInit, Renderer2 } from "@angular/core";
import { AlertController, ModalController } from "@ionic/angular";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { TermineService } from "../../api/services/termine.service";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { KontaktdatenAnsichtModalComponent } from "../kontaktdaten-ansicht-modal/kontaktdaten-ansicht-modal.component";
import { TextvorlageComponent } from "../../textvorlage/textvorlage.component";
import { AuthService } from "../../services/auth.service";
import {
  UserRole,
  TerminStatus,
  TextbausteinPlatzhalter,
} from "../enums/global-enums";
import { DetailAktDataRetrieveResponse } from "../../api/models/detail-akt-data-retrieve-response";
import { KontaktDetail } from "../../api/models/kontakt-detail";
import { Kontakt } from "../../api/models/kontakt";
import {
  getKontaktBezeichnungByKuerzel,
  translateQuillEditorElements,
} from "../global-functions/global-functions";
import { TextvorlagenService } from "../../api/services/textvorlagen.service";
import { LoveItToastService } from "../../services/love-it-toast.service";

enum EmpfaengerGruppe {
  to = "An",
  cc = "Cc",
  bcc = "Bcc",
}

interface EmailRecipient {
  empfaengerGruppe: EmpfaengerGruppe;
  name: string;
  emailAddress: string;
  required: boolean;
  person: KontaktDetail;
}

@Component({
  selector: "app-email-versenden",
  templateUrl: "./email-versenden.component.html",
  styleUrls: ["./email-versenden.component.scss"],
  standalone: false,
})
export class EmailVersendenComponent implements OnInit {
  @Input({ required: true }) aktId: number;
  @Input({ required: true }) aktTitle: number;
  @Input({ required: true }) terminBestaetigenFormGroup: UntypedFormGroup;
  @Input({ required: true }) terminId: number;
  @Input({ required: true }) details: DetailAktDataRetrieveResponse;
  @Input({ required: true }) kommentarBemerkungText: string;
  isSaving: boolean = false;
  submitted: boolean = false;
  emailVersendenFormGroup: UntypedFormGroup;
  kontakteDropdownOptions: { value: KontaktDetail; text: string }[] = [];
  readonly backofficeName: string = "Backoffice";
  readonly backofficeEmail: string = "office@faircheck.at";
  readonly textbausteinKategorie: string = "E-Mail";
  readonly defaultBackofficeDropdownOption: {
    value: KontaktDetail;
    text: string;
  } = {
    value: {
      Id: null,
      Email: this.backofficeEmail,
      Name: this.backofficeName,
    },
    text: `${this.backofficeName} | ${this.backofficeEmail} | FCA`,
  };
  quillEditorModules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
      ["clean"],
    ],
  };

  constructor(
    private modalController: ModalController,
    private termineService: TermineService,
    private oidcSecurityService: OidcSecurityService,
    private alertController: AlertController,
    private fb: FormBuilder,
    private loveItToastService: LoveItToastService,
    private authService: AuthService,
    private textvorlagenService: TextvorlagenService,
    protected renderer: Renderer2,
  ) {}

  ngOnInit() {
    let defaultBetreff: string = `Betreffend Auftrag [${this.aktTitle}] Schadennummer [${this.details.Incident_number}]`;
    if (this.details.Contacts?.Ansprechpartner?.Name) {
      defaultBetreff += ` Ansprechpartner [${this.details.Contacts?.Ansprechpartner?.Name}]`;
    }
    if (this.details.Contacts?.FCA?.Email === this.backofficeEmail) {
      this.defaultBackofficeDropdownOption.value = this.details.Contacts?.FCA;
      this.defaultBackofficeDropdownOption.value.Name = this.backofficeName;
    }

    const defaultRecipients: EmailRecipient[] = [];

    const requiredDefaultToBackofficeStatus: TerminStatus[] = [
      TerminStatus.NichtMoeglichPostweg,
      TerminStatus.SpaeterMoeglich,
    ];
    if (
      requiredDefaultToBackofficeStatus.includes(
        this.terminBestaetigenFormGroup?.get("TerminStatus")?.value,
      )
    ) {
      defaultRecipients.push({
        empfaengerGruppe: EmpfaengerGruppe.to,
        name: `${
          this.defaultBackofficeDropdownOption.value.Vorname
            ? this.defaultBackofficeDropdownOption.value.Vorname + " "
            : ""
        }${this.defaultBackofficeDropdownOption.value.Name ?? ""}`,
        emailAddress: this.defaultBackofficeDropdownOption.value.Email,
        required: true,
        person: this.defaultBackofficeDropdownOption.value,
      } as EmailRecipient);
    }

    if (this.details.Contacts?.VUSB?.Email) {
      defaultRecipients.push({
        empfaengerGruppe: EmpfaengerGruppe.to,
        name: `${
          this.details.Contacts?.VUSB?.Vorname
            ? this.details.Contacts?.VUSB?.Vorname + " "
            : ""
        }${this.details.Contacts?.VUSB?.Name ?? ""}`,
        emailAddress: this.details.Contacts?.VUSB?.Email,
        required: true,
        person: this.details.Contacts?.VUSB,
      } as EmailRecipient);
    }

    this.emailVersendenFormGroup = this.fb.group({
      emailBetreff: [defaultBetreff, Validators.required],
      emailText: [null, Validators.required],
      emailRecipients: new FormControl<EmailRecipient[]>(
        defaultRecipients,
        this.recipientsValidator(),
      ),
    });
    this.emailVersendenFormGroup.disable();
    this.textvorlagenService
      .textvorlagenRetrieveFirstList({
        benutzer: this.oidcSecurityService.getPayloadFromIdToken().sb,
        kategorie: this.textbausteinKategorie.toLowerCase(),
        zuordnung: this.terminBestaetigenFormGroup
          .get("TerminStatus")
          .value.toString(),
      })
      .subscribe({
        next: (response) => {
          this.emailVersendenFormGroup
            .get("emailText")
            .setValue(this.replacePlatzhalterForString(response?.inhalt));

          this.emailVersendenFormGroup.enable();
        },
        error: (error) => {
          this.emailVersendenFormGroup.enable();
        },
      });
    this.loadKontaktDropdownOptions();
  }

  cancelButton() {
    if (this.emailVersendenFormGroup.dirty) {
      this.abbrechenBestaetigenDialogAnzeigen();
    } else {
      this.modalController.dismiss(null, "cancel");
    }
  }

  async submit() {
    if (!this.terminBestaetigenFormGroup) {
      return;
    }
    this.submitted = true;
    this.validateEmailRecipientsArray();
    if (this.emailVersendenFormGroup.invalid) {
      this.emailVersendenFormGroup.markAllAsTouched();
      return;
    }

    const alert = await this.alertController.create({
      header: "Bestätigen abschließen",
      message: "Der Termin wird bestätigt und die E-Mail wird versendet.",
      buttons: [
        {
          text: "Abbrechen",
          role: "cancel",
          cssClass: "color-primary",
        },
        {
          cssClass: "color-secondary",
          text: "Abschließen",
          handler: async () => {
            this.isSaving = true;
            this.terminBestaetigen()
              .then(() => this.sendEmail())
              .then(() => {
                this.isSaving = false;
                this.modalController.dismiss(null, "confirm");
              })
              .catch(async (error) => {
                this.isSaving = false;
                this.loveItToastService.showToastMessage(error.message);
              });
          },
        },
      ],
    });

    await alert.present();
  }

  async terminBestaetigen() {
    const dateValue = this.terminBestaetigenFormGroup.get("DatumNeu")?.value;
    const timeValue = this.formatTime(
      this.terminBestaetigenFormGroup.get("UhrzeitNeu")?.value,
    );
    const dateParameter =
      dateValue && timeValue ? `${dateValue}T${timeValue}` : null;

    return new Promise<void>((resolve, reject) => {
      this.termineService
        .termineOdataKundenterminBestaetigenCreate({
          token: this.oidcSecurityService.getToken(),
          BemerkungNeu:
            this.terminBestaetigenFormGroup.get("BemerkungNeu").value,
          BetreffNeu: this.terminBestaetigenFormGroup.get("BetreffNeu").value,
          DatumNeu: dateParameter,
          KontaktDatenRecherche: this.terminBestaetigenFormGroup.get(
            "KontaktDatenRecherche",
          ).value,
          TerminStatus:
            this.terminBestaetigenFormGroup.get("TerminStatus").value,
          TerminId: this.terminId,
        })
        .subscribe({
          next: (result) => {
            resolve();
          },
          error: async (error) => {
            const message = `Fehler beim Bestätigen des Kundentermins!`;
            reject(new Error(message));
          },
        });
    });
  }

  async sendEmail() {
    return new Promise<void>((resolve, reject) => {
      const emailBetreff =
        this.emailVersendenFormGroup.get("emailBetreff")?.value;
      const emailText = this.emailVersendenFormGroup.get("emailText")?.value;
      const emailRecipients: EmailRecipient[] =
        this.emailVersendenFormGroup.get("emailRecipients")?.value;
      const to = emailRecipients
        .filter((r) => r.empfaengerGruppe == EmpfaengerGruppe.to)
        .map((r) => this.getRecipientEmailWithName(r))
        .join(", ");
      const cc = emailRecipients
        .filter((r) => r.empfaengerGruppe == EmpfaengerGruppe.cc)
        .map((r) => this.getRecipientEmailWithName(r))
        .join(", ");
      const bcc = emailRecipients
        .filter((r) => r.empfaengerGruppe == EmpfaengerGruppe.bcc)
        .map((r) => this.getRecipientEmailWithName(r))
        .join(", ");

      const date = new Date().toUTCString();

      this.termineService
        .termineOdataKundenterminEmailsendenCreate({
          token: this.oidcSecurityService.getToken(),
          akt_id: this.aktId,
          to: to,
          cc: cc,
          bcc: bcc,
          subject: emailBetreff,
          message: emailText,
          date: date,
        })
        .subscribe({
          next: (result) => {
            resolve();
          },
          error: async (error) => {
            const message = "Fehler beim Senden der E-Mail!";
            reject(new Error(message));
          },
        });
    });
  }

  async openPersonAnsichtModal(person: KontaktDetail) {
    const modal = await this.modalController.create({
      component: KontaktdatenAnsichtModalComponent,
      cssClass: [],
      componentProps: {
        titel: "",
        enableEdit: false,
        anspruchsteller: person.Name,
        auftrag: this.details.Title,
        schadensnummer: this.details.Incident_number,
        fca: this.details.Contacts?.FCA,
        instance: person,
        akt_id: this.aktId,
      },
    });

    await modal.present();

    const { data, role } = await modal.onWillDismiss();
    if (data) {
      console.log("Modal dismissed with data:", data);
    }
  }

  async openTextbausteinModal(formControl, field: string) {
    const modal = await this.modalController.create({
      component: TextvorlageComponent,
      cssClass: "textvorlageModal",
      backdropDismiss: false,
      componentProps: {
        content: formControl.value,
        field,
        allowTextbausteinEditing: this.authService.hasCurrentUserAccessForRole(
          UserRole.BackofficeAdmin,
        ),
        zuordnung: this.terminBestaetigenFormGroup
          .get("TerminStatus")
          .value.toString(),
      },
    });

    modal.onDidDismiss().then((data) => {
      const d = data.data;
      if (d && d.keep) {
        formControl.patchValue(this.replacePlatzhalterForString(d.content));
        formControl.markAsDirty();
      }
    });

    await modal.present();
  }

  get emailRecipientArray(): EmailRecipient[] {
    return this.emailVersendenFormGroup.get("emailRecipients")
      .value as EmailRecipient[];
  }

  recipientsValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const errors: ValidationErrors = {};
      if (control.value.length == 0) {
        return {
          customError: "Kein Empfänger eingetragen!",
        };
      }

      for (let i = 0; i < control.value.length; i++) {
        const entry: EmailRecipient = control.value[i];
        const currentKey: string = `customError${i}`;

        if (entry.emailAddress === "") {
          errors[currentKey] = "E-Mail Adresse darf nicht leer sein!";
          continue;
        }

        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        const isValidEmail = emailRegex.test(entry.emailAddress);
        if (!isValidEmail) {
          errors[currentKey] = "Ungültige E-Mail Adresse!";
          continue;
        }

        if (isValidEmail && !entry.empfaengerGruppe) {
          errors[currentKey] = "Empfängergruppe für E-Mail nicht gesetzt!";
          continue;
        }
      }

      return Object.keys(errors).length > 0 ? errors : null;
    };
  }

  validateEmailRecipientsArray() {
    this.emailVersendenFormGroup
      .get("emailRecipients")
      .updateValueAndValidity();
  }

  addRecipient() {
    this.emailRecipientArray.push({
      empfaengerGruppe: EmpfaengerGruppe.to,
      emailAddress: "",
      required: false,
      person: null,
    } as EmailRecipient);
  }

  setEmpfaengergruppeForRecipientByIndex(
    index: number,
    empfaengerGruppe: EmpfaengerGruppe,
  ) {
    this.emailRecipientArray[index].empfaengerGruppe = empfaengerGruppe;
    this.validateEmailRecipientsArray();
  }

  editNameForRecipientByIndex(index: number, newName: string) {
    this.emailRecipientArray[index].name = newName;
    this.validateEmailRecipientsArray();
  }

  editEmailAddressForRecipientByIndex(index: number, newEmail: string) {
    this.emailRecipientArray[index].emailAddress = newEmail;
    this.validateEmailRecipientsArray();
  }

  removeRecipientByIndex(index: number) {
    this.emailRecipientArray.splice(index, 1);
    this.validateEmailRecipientsArray();
    this.loadKontaktDropdownOptions();
  }

  async abbrechenBestaetigenDialogAnzeigen() {
    const alert = await this.alertController.create({
      header: "Ungespeicherte Änderungen vorhanden",
      message:
        "Wollen Sie alle Änderungen an der E-Mail verwerfen und das Fenster schließen?",
      buttons: [
        {
          cssClass: "color-primary",
          text: "Abbrechen",
          role: "cancel",
        },
        {
          cssClass: "color-danger",
          text: "Verwerfen",
          handler: () => {
            this.modalController.dismiss(null, "cancel");
          },
        },
      ],
    });
    await alert.present();
  }

  getRecipientEmailWithName(recipient: EmailRecipient) {
    if (recipient.name) {
      return `${recipient.name}<${recipient.emailAddress}>`;
    }
  }

  isKontaktDetail(value: any): value is KontaktDetail {
    return (
      value && typeof value.Name === "string" && typeof value.Email === "string"
    );
  }

  loadKontaktDropdownOptions() {
    this.kontakteDropdownOptions = [];
    let backofficeExists = false;
    for (const key in this.details.Contacts) {
      const value: KontaktDetail = this.details.Contacts[key as keyof Kontakt];
      if (this.isKontaktDetail(value) && value.Email) {
        if (value.Email === this.backofficeEmail) {
          backofficeExists = true;
          value.Name = this.backofficeName;
        }
        this.kontakteDropdownOptions.push({
          value: value,
          text: `${value.Vorname ? value.Vorname + " " : ""}${
            value.Name ?? ""
          }${value.Email ? " | " + value.Email : ""}${
            getKontaktBezeichnungByKuerzel(key)
              ? " | " + getKontaktBezeichnungByKuerzel(key)
              : ""
          }`,
        });
      }
    }
    const index = this.kontakteDropdownOptions.findIndex(
      (option) => option.value.Email === this.backofficeEmail,
    );

    if (index !== -1) {
      const [foundOption] = this.kontakteDropdownOptions.splice(index, 1);
      this.kontakteDropdownOptions = [
        foundOption,
        ...this.kontakteDropdownOptions,
      ];
    }
    if (!backofficeExists) {
      this.kontakteDropdownOptions = [
        this.defaultBackofficeDropdownOption,
        ...this.kontakteDropdownOptions,
      ];
    }
    const recipients: EmailRecipient[] = this.emailVersendenFormGroup?.get(
      "emailRecipients",
    ).value as EmailRecipient[];
    this.kontakteDropdownOptions = this.kontakteDropdownOptions.filter(
      (option) =>
        !recipients
          .map((recipient) => recipient.emailAddress)
          .includes(option.value.Email),
    );
  }

  kontakteDropdownChanged(value: KontaktDetail) {
    this.emailVersendenFormGroup.get("emailRecipients").value.push({
      empfaengerGruppe: EmpfaengerGruppe.to,
      name: `${value.Vorname ? value.Vorname + " " : ""}${value.Name ?? ""}`,
      emailAddress: value.Email,
      required: false,
      person: value,
    } as EmailRecipient);
    this.loadKontaktDropdownOptions();
  }

  replacePlatzhalterForString(text: string): string {
    const dateValue = this.terminBestaetigenFormGroup.get("DatumNeu")?.value;
    const timeValue = this.formatTime(
      this.terminBestaetigenFormGroup.get("UhrzeitNeu")?.value,
    );

    if (dateValue && timeValue) {
      text = text.replace(
        new RegExp(TextbausteinPlatzhalter.Besichtigungstermin, "g"),
        `${new Date(dateValue).toLocaleDateString("de-DE", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })} gegen ${timeValue} Uhr (+- 30min.)`,
      );
    } else {
      text = text.replace(
        new RegExp(TextbausteinPlatzhalter.Besichtigungstermin, "g"),
        ``,
      );
    }

    const kundenprozessLink: string = `https://fairapp.faircheck.at/kundenprozess?aktid=${this.aktId}&amp;snr=${this.details.Incident_number}`;
    text = text.replace(
      new RegExp(TextbausteinPlatzhalter.KundenprozessLink, "g"),
      `<a href="${kundenprozessLink}" rel="noopener noreferrer" target="_blank">${kundenprozessLink}</a>`,
    );

    text = text.replace(new RegExp("\n", "g"), `<br>`);

    return text;
  }

  formatTime(date: Date): string {
    return new Intl.DateTimeFormat("de-DE", {
      hour: "2-digit",
      minute: "2-digit",
      hourCycle: "h23",
    }).format(date);
  }

  protected readonly EmpfaengerGruppe = EmpfaengerGruppe;
  protected readonly translateQuillEditorElements =
    translateQuillEditorElements;
}
