import { KontaktDetail } from "../../api/models/kontakt-detail";
import { Renderer2 } from "@angular/core";

export function getLowerCaseInputValue(input: string): string {
  return input ? input.toLowerCase() : null;
}

export function getStringFilterConditionForDatatable(
  datavalue: string,
  filtervalue: string,
): boolean {
  return (
    (datavalue !== null &&
      filtervalue !== null &&
      datavalue.toLowerCase().indexOf(filtervalue) !== -1) ||
    !filtervalue
  );
}

export function replaceFileExtensionConditionally(fileName: string): string {
  if (fileName.endsWith(".pms")) {
    return fileName.slice(0, -4) + ".eml";
  }

  return fileName;
}

export function isAktArtTechnik(aktArt: string): boolean {
  const technikSparten: string[] = [
    "Blitzschadenprüfstelle A",
    "E-Geräteschaden",
    "Maschinenbruch",
    "Verkehrstechnik",
  ];
  return technikSparten.includes(aktArt);
}

export function getKontaktBezeichnungByKuerzel(kuerzel: string): string {
  switch (kuerzel) {
    case "AST":
      return "AnspruchstellerIn";
    case "Ansprechpartner":
      return "Ansprechpartner";
    case "FCA":
      return "FCA";
    case "SB2":
      return "Schadenregulierer";
    case "VN":
      return "VersicherungsnehmerIn";
    case "VU":
      return "Versicherung";
    case "VUSB":
      return "SachbearbeiterIn";
    case "Vermittler":
      return "VermittlerIn";
    default:
      return "";
  }
}

export function isKontaktAllowedForEdit(kontaktDetail: KontaktDetail): boolean {
  return !(
    kontaktDetail.Name.startsWith("WEG") ||
    kontaktDetail.Name.startsWith("N.N.")
  );
}

export function translateQuillEditorElements(renderer: Renderer2) {
  function replaceTextWithQuerySelector(
    querySelector: string,
    newText: string,
    pseudoElement?: string,
  ) {
    const element = document.querySelector(querySelector);

    if (element) {
      const style = renderer.createElement("style");
      style.textContent = `
        ${querySelector}${pseudoElement} {
          content: '${newText}' !important;
        }
      `;
      renderer.appendChild(document.head, style);
    }
  }

  const translations = [
    {
      selector: 'span.ql-picker-item[data-value="1"]',
      newText: "Überschrift groß",
      pseudoElement: "::before",
    },
    {
      selector: 'span.ql-picker-item[data-value="2"]',
      newText: "Überschrift normal",
      pseudoElement: "::before",
    },
    {
      selector: 'span.ql-picker-item[data-value="3"]',
      newText: "Überschrift klein",
      pseudoElement: "::before",
    },
    {
      selector: "span.ql-header span.ql-picker-item:not([data-value])",
      newText: "Normaltext",
      pseudoElement: "::before",
    },
    {
      selector: 'span.ql-header span.ql-picker-label[data-value="1"]',
      newText: "Ü. groß",
      pseudoElement: "::before",
    },
    {
      selector: 'span.ql-header span.ql-picker-label[data-value="2"]',
      newText: "Ü. normal",
      pseudoElement: "::before",
    },
    {
      selector: 'span.ql-header span.ql-picker-label[data-value="3"]',
      newText: "Ü. klein",
      pseudoElement: "::before",
    },
    {
      selector: "span.ql-header span.ql-picker-label",
      newText: "Normaltext",
      pseudoElement: "::before",
    },
    {
      selector: 'div.ql-tooltip.ql-editing[data-mode="link"]',
      newText: "Link eingeben:",
      pseudoElement: "::before",
    },
    {
      selector: 'div.ql-tooltip.ql-editing[data-mode="link"] a.ql-action',
      newText: "Speichern",
      pseudoElement: "::after",
    },
    {
      selector: "div.ql-tooltip:not([data-mode])",
      newText: "URL besuchen:",
      pseudoElement: "::before",
    },
    {
      selector: "div.ql-tooltip:not([data-mode]) a.ql-action",
      newText: "Bearbeiten",
      pseudoElement: "::after",
    },
    {
      selector: "div.ql-tooltip:not([data-mode]) a.ql-remove",
      newText: "Entfernen",
      pseudoElement: "::before",
    },
  ];

  translations.forEach(({ selector, newText, pseudoElement }) => {
    replaceTextWithQuerySelector(selector, newText, pseudoElement);
  });

  const element = document.querySelector(
    'input[data-formula="e=mc^2"][data-link="https://quilljs.com"][data-video="Embed URL"][placeholder="https://quilljs.com"]',
  );

  if (element) {
    renderer.setProperty(element, "placeholder", "https://faircheck.at/");
  }
}
