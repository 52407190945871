/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { OdataDokumentResponse } from '../models/odata-dokument-response';
import { FairAppSimpleRequest } from '../models/fair-app-simple-request';
import { OdataDokumentRequest } from '../models/odata-dokument-request';
import { OdataDokumenteSortPatch } from '../models/odata-dokumente-sort-patch';
@Injectable({
  providedIn: 'root',
})
class DokumenteService extends __BaseService {
  static readonly dokumenteOdataAktCreatePath = '/dokumente/odata/akt';
  static readonly dokumenteOdataAktDownloadCreatePath = '/dokumente/odata/akt/download';
  static readonly dokumenteOdataGutachtenCreatePath = '/dokumente/odata/gutachten';
  static readonly dokumenteOdataGutachtenDeleteCreatePath = '/dokumente/odata/gutachten/delete';
  static readonly dokumenteOdataGutachtenDownloadCreatePath = '/dokumente/odata/gutachten/download';
  static readonly dokumenteOdataGutachtenSortallCreatePath = '/dokumente/odata/gutachten/sortall';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param data undefined
   */
  dokumenteOdataAktCreateResponse(data: FairAppSimpleRequest): __Observable<__StrictHttpResponse<OdataDokumentResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/dokumente/odata/akt`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OdataDokumentResponse>;
      })
    );
  }
  /**
   * @param data undefined
   */
  dokumenteOdataAktCreate(data: FairAppSimpleRequest): __Observable<OdataDokumentResponse> {
    return this.dokumenteOdataAktCreateResponse(data).pipe(
      __map(_r => _r.body as OdataDokumentResponse)
    );
  }

  /**
   * @param data undefined
   */
  dokumenteOdataAktDownloadCreateResponse(data: OdataDokumentRequest): __Observable<__StrictHttpResponse<OdataDokumentRequest>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/dokumente/odata/akt/download`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OdataDokumentRequest>;
      })
    );
  }
  /**
   * @param data undefined
   */
  dokumenteOdataAktDownloadCreate(data: OdataDokumentRequest): __Observable<OdataDokumentRequest> {
    return this.dokumenteOdataAktDownloadCreateResponse(data).pipe(
      __map(_r => _r.body as OdataDokumentRequest)
    );
  }

  /**
   * @param data undefined
   */
  dokumenteOdataGutachtenCreateResponse(data: FairAppSimpleRequest): __Observable<__StrictHttpResponse<FairAppSimpleRequest>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/dokumente/odata/gutachten`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FairAppSimpleRequest>;
      })
    );
  }
  /**
   * @param data undefined
   */
  dokumenteOdataGutachtenCreate(data: FairAppSimpleRequest): __Observable<FairAppSimpleRequest> {
    return this.dokumenteOdataGutachtenCreateResponse(data).pipe(
      __map(_r => _r.body as FairAppSimpleRequest)
    );
  }

  /**
   * @param data undefined
   */
  dokumenteOdataGutachtenDeleteCreateResponse(data: OdataDokumentRequest): __Observable<__StrictHttpResponse<OdataDokumentRequest>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/dokumente/odata/gutachten/delete`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OdataDokumentRequest>;
      })
    );
  }
  /**
   * @param data undefined
   */
  dokumenteOdataGutachtenDeleteCreate(data: OdataDokumentRequest): __Observable<OdataDokumentRequest> {
    return this.dokumenteOdataGutachtenDeleteCreateResponse(data).pipe(
      __map(_r => _r.body as OdataDokumentRequest)
    );
  }

  /**
   * @param data undefined
   */
  dokumenteOdataGutachtenDownloadCreateResponse(data: OdataDokumentRequest): __Observable<__StrictHttpResponse<OdataDokumentRequest>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/dokumente/odata/gutachten/download`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OdataDokumentRequest>;
      })
    );
  }
  /**
   * @param data undefined
   */
  dokumenteOdataGutachtenDownloadCreate(data: OdataDokumentRequest): __Observable<OdataDokumentRequest> {
    return this.dokumenteOdataGutachtenDownloadCreateResponse(data).pipe(
      __map(_r => _r.body as OdataDokumentRequest)
    );
  }

  /**
   * @param data undefined
   */
  dokumenteOdataGutachtenSortallCreateResponse(data: OdataDokumenteSortPatch): __Observable<__StrictHttpResponse<OdataDokumenteSortPatch>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/dokumente/odata/gutachten/sortall`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OdataDokumenteSortPatch>;
      })
    );
  }
  /**
   * @param data undefined
   */
  dokumenteOdataGutachtenSortallCreate(data: OdataDokumenteSortPatch): __Observable<OdataDokumenteSortPatch> {
    return this.dokumenteOdataGutachtenSortallCreateResponse(data).pipe(
      __map(_r => _r.body as OdataDokumenteSortPatch)
    );
  }
}

module DokumenteService {
}

export { DokumenteService }
